<!--
 * @Author: 张阳帅
 * @Date: 2024-09-23 17:52:37
 * @LastEditTime: 2024-12-03 16:13:23
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \szlsnk-user-mp\src\views\gjc\sever\seleteDept.vue
-->
<template>
  <div class="appoint">
    <div ref="hosInfo" class="hosTitle" @click="routerTo"> 
      <img class="hosp-image" :src="hospData.ghymtp">
      <div class="hosInfo">
        <div class="name">
          {{ hospData.hospName }}
          <van-icon
            class="number"
            name="arrow"
            color="#9F9F9F"
            size="20"
          />
        </div>
        <div class="adress">
          {{ hospData.address }}
        </div>
      </div>
    </div>
    <van-tree-select :height="height" :items="deptList" :main-active-index.sync="select_active" @click-item="onNavClick" />
    <van-dialog v-model="show" :title="hospInfo.descTitle" show-confirm-button theme="round-button" confirm-button-text="我知道了" confirm-button-color="#3A8AE5">
      <div class="body" v-html="hospInfo.content" />
    </van-dialog>
  </div>
</template>
<script>
import {gjcModel} from '../../../api/gjcApi'
import {setItem, getItem } from '../../../utils/cache'
export default {
    data(){
        return{
            deptList:[], //科室列表
            select_active: 0,
            show:false,  //预约须知
            hospData:{},
            hospInfo:{},
            flag:'',
            yyxzStatus:0,
            routerPath:'',
            height:0
        }
    },
    mounted() {
      // 重绘高度
      window.addEventListener('resize', this.updateHeight)
      this.caclHeight()
      this.getQueryRegisterDept()
      this.getQueryRegisterData()
    },
    created(){
      this.yyxzStatus = getItem("yyxzStatus")
      this.gjcDepartDesc()
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.updateHeight)
    }, 
    methods:{
      caclHeight(){
      // 获取hosTitle元素
      const hosTitleElement = document.querySelector('.hosTitle')
      // 获取hosTitle元素相对于其offsetParent的顶部距离
      const offsetTop = hosTitleElement.offsetTop
      // 获取hosTitle元素的高度
      const height = hosTitleElement.offsetHeight
      // 获取offsetParent元素相对于视口顶部的距离
      const parentOffsetTop = hosTitleElement.offsetParent.offsetTop
      // 计算hosTitle底部距离页面顶部的高度
      const totalHeight = offsetTop + height + parentOffsetTop
      console.log('hosTitle底部距离页面顶部的高度:', totalHeight)
      this.height = window.innerHeight - totalHeight
      },
        //获取科室列表
        getQueryRegisterDept(){
            gjcModel.getQueryRegisterDept({"hospCode":''}).then((result) => {
                result.data && result.data.forEach((item)=> {
                    const selectDeps = {}
                    selectDeps.text = item.deptName
                    selectDeps.id = parseInt(item.deptCode)
                    const childArray = []
                    item.childList.forEach((children)=> {
                        const smallDeps = {}
                        smallDeps.text = children.deptName
                        smallDeps.id = parseInt(children.deptCode)
                        childArray.push(smallDeps)
                    })
                    selectDeps.children = childArray
                    this.deptList.push(selectDeps)
                })
            })
           
        },
        
        gjcDepartDesc(){
          gjcModel.gjcDepartDesc('YHXZ').then((result) => {
                this.hospInfo = result.data
                if (this.yyxzStatus === 1) {
                  this.show = true
                  setItem("yyxzStatus",0)
                }
            })
        },
        // 医院介绍
        getQueryRegisterData(){
            const params = {
                yydm: '' //医院代码（医院唯一标识）
            }
            gjcModel.getHospitalIntroductionData(params).then((result) => {
                this.hospData = result.data
            })
        },
        routerTo(){
          this.$router.push({
            path: '/gjcHosp'
          })
        },
        onNavClick(item){
            console.log('选择科室', item)
            this.$router.push({
                path: '/deptSource',
                query: {
                    deptCode:String(item.id)
                    // deptName:item.text
                }
            })
        }
    }
}
</script>
<style scoped lang='less'>
.van-sidebar-item--select{
color: #1e8dff !important;
font-weight: bold;
font-family: PingFangSC, PingFang SC;
}
.van-sidebar-item--select::before{
  background-color: #1e8dff !important;
}
.hosTitle{
  display: flex;
  padding: 24px 28px;
}
.van-dialog{
  top: 50% !important;
}
.hosp-image{
  border-radius: 8px;
  width: 180px;
  height: 120px;
  margin-right: 24px;
}
.hosInfo{
  font-size: 28px;
  .adress{
    font-size: 26px;
    color: #9CA0AB;
    line-height: 36px;
    margin: 6px 32px 0 0;
  }
  .name{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 30px;
    color: #222B31;
    line-height: 42px;
    letter-spacing: 1px;
  }
}
.body{
  padding: 10px 20px;
  font-size: 26px;
  color: #222b31;
  line-height: 1.5;
}
</style>
